
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { Link as GatsbyLink } from "gatsby";

import { GatsbyImage } from "gatsby-plugin-image";


import LazyLoad from 'react-lazyload';

const config = {};

export const hourOutput = (date) => {
  const d = new Date(date);
  return `${d.toLocaleString('en-US', { hour: 'numeric', hour12: true })}`;
};

export const formatDate = (date, options) => {
  let d = new Date(date);
  return d.toLocaleDateString('en-US', options);
};

export const dateString = (date) => {
  return `${formatDate(date, {weekday: 'long'})}, ${formatDate(date, {month: 'long'})} ${formatDate(date, {day: 'numeric'})}`;
};

export const eventURL = (event) => {
  return `/event/${event.slug}`;
};


export const EventTile = (props) => {
    const event = props.event;
    return (
        <Grid item key={props.event.id} xs={12} sm={6} md={4}>
          <Card style={{height: '100%', display: 'flex', flexDirection: 'column',}}>
            {(event.cover) ? <Link to={eventURL(event)} component={GatsbyLink}><LazyLoad height={160}><CardMedia><GatsbyImage
              image={event.cover.localFile.childImageSharp.gatsbyImageData}
              title={event.title}
              alt={event.title}
            /></CardMedia></LazyLoad></Link> : <></>}
            <CardContent style={{flexGrow: 1}}>
              <Typography gutterBottom variant="h5" component="h2">
                <Link to={eventURL(event)} component={GatsbyLink}>{event.title}</Link>
              </Typography>
              {/* <Typography>
                {event.description}
              </Typography> */}
              <Typography>
                {event.venue.name}
              </Typography>
              <Typography>
                {`${hourOutput(event.start)} - ${hourOutput(event.end)}`}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
};



// export {EventTile};