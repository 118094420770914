import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const HeaderImage = () => (
    <StaticImage
        src="../images/icon.png"
        alt="Rave Texas"
        placeholder="blurred"
        layout="fixed"
        width={172}
        height={56}
        style={{paddingTop: "10px"}}
    />
)
