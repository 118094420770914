import React from 'react';

import { Helmet } from 'react-helmet';


import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import {Link as GatsbyLink} from 'gatsby';

import {HeaderImage} from './headerImage';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));


export const PageContainer = (props) => {
  const classes = useStyles();


  return (
    <React.Fragment>
        <Helmet>
            <meta name="twitter:site" content="@rave_texas" />
            <meta name="twitter:creator" content="@rave_texas" />
        </Helmet>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar style={{backgroundColor: "#663399"}}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            style={{paddingTop: "10px"}}
          >
            <Link to="/" component={GatsbyLink}><HeaderImage></HeaderImage></Link>
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {props.children}
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Any issues or want your event posted, contact <Link href="mailto:events@ravetexas.com">events@ravetexas.com</Link>
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          made with ❤️ by <Link href="https://jamesdrakewilson.com">james wilson</Link>
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          We try to ensure all of our data is as accurate as possible, but always verify any event details with the venue and assocaited websites
        </Typography>
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
};
